import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { IoCallOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { motion, MotionConfig } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import './i18n';
import AccessibilityButton from './components/AccessibilityButton';

const Home = () => {
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setIsMenuOpen(false); // Close the menu after selecting a language
    };

    const currentLang = i18n.language;
    const isRTL = currentLang === 'he' || currentLang === 'ar';

    const handleNavigate = () => {
        navigate('/preview');
    }

    return (
        <>

            <AccessibilityButton />
            {/* Language Selector for Desktop */}
            <div className="absolute top-4 right-4 hidden md:flex" dir="ltr">
                {['en', 'he', 'ar'].map((lang) => (
                    <button
                        key={lang}
                        onClick={() => changeLanguage(lang)}
                        className="px-2 py-1 text-sm bg-blue-500 text-white rounded mr-1 md:px-3 md:py-1 md:text-base"
                    >
                        {lang.toUpperCase()}
                    </button>
                ))}
            </div>

            {/* Mobile Menu Button */}
            <div className="absolute top-4 right-4 md:hidden">
                <MotionConfig transition={{ duration: 0.3, ease: "easeInOut" }}>
                    <motion.button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className="p-4 bg-blue-500 w-10 h-10 text-white rounded focus:outline-none"
                        aria-label="Toggle menu"
                        animate={isMenuOpen ? 'open' : 'closed'}
                    >
                        <motion.span style={{ left: "50%", top: "35%", x: "-50%", y: "-50%" }} variants={{
                            open: { rotate: ["0deg", "0deg", "45deg"], top: ["35%", "50%", "50%"] },
                            closed: { rotate: ["45deg", "0deg", "0deg"], top: ["50%", "50%", "35%"] },
                        }} className='absolute w-8 h-1 bg-white' />
                        <motion.span style={{ left: "50%", top: "50%", x: "-50%", y: "-50%" }} variants={{
                            open: { rotate: ["0deg", "0deg", "-45deg"] },
                            closed: { rotate: ["-45deg", "0deg", "0deg"] },
                        }} className='absolute w-8 h-1 bg-white' />
                        <motion.span style={{ left: "calc(50% + 8px)", bottom: "35%", x: "-50%", y: "50%" }} variants={{
                            open: {
                                rotate: ["0deg", "0deg", "45deg"],
                                left: "50%",
                                bottom: ["35%", "50%", "50%"]
                            },
                            closed: {
                                rotate: ["45deg", "0deg", "0deg"],
                                left: "calc(50% + 8px)",
                                bottom: ["50%", "50%", "35%"]
                            },
                        }} className='absolute w-4 h-1 bg-white' />
                    </motion.button>
                </MotionConfig>
            </div>

            {/* Mobile Language Selector */}
            {isMenuOpen && (
                <div className="absolute top-16 right-4 bg-white shadow-lg rounded-lg p-3 flex flex-col items-start space-y-2 md:hidden z-10">
                    {['en', 'he', 'ar'].map((lang) => (
                        <button
                            key={lang}
                            onClick={() => changeLanguage(lang)}
                            className="px-2 py-1 bg-blue-500 text-white rounded w-full text-left"
                        >
                            {lang.toUpperCase()}
                        </button>
                    ))}
                </div>
            )}

            <div
                className="flex flex-col justify-center items-center text-gray-800 p-4 mt-20 w-11/12 sm:w-5/6 md:w-2/3 lg:w-2/5 rounded-3xl mx-auto overflow-hidden"
                dir={isRTL ? 'rtl' : 'ltr'}
                style={{
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    backdropFilter: "blur(10px)",
                    WebkitBackdropFilter: "blur(10px)",
                }}
            >
                {/* Hero Section */}
                <section className="flex flex-col items-center text-center px-2 sm:px-4 max-w-xl">
                    <h1 className="text-lg sm:text-2xl md:text-4xl font-extrabold mb-3 text-white">{t('hero.title')}</h1>
                    <p className="text-sm sm:text-base md:text-lg mb-4 text-white">{t('hero.subtitle')}</p>

                    {Array.from({ length: 7 }, (_, i) => (
                        <p key={i} className="text-sm sm:text-base md:text-lg mb-1 text-white">
                            {t(`hero.message.line${i + 1}`)}
                        </p>
                    ))}

                    <p className="text-base md:text-lg font-semibold text-white mb-4">{t('hero.message.cta')}</p>

                    {/* Link to Business Card Preview Route */}
                    <button
                        onClick={() => handleNavigate()}
                        className="bg-blue-500 text-white font-medium py-1 px-4 sm:py-2 sm:px-6 rounded-full hover:bg-blue-600 transition duration-300"
                    >
                        {t('hero.previewButton')}
                    </button>
                </section>
            </div>

            {/* Contact Buttons - Fixed to Bottom */}
            <div className="fixed bottom-3 right-2 flex space-x-2">
                <a
                    href="https://wa.me/972523773738"
                    className="bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-white hover:text-green-500 transition duration-300"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaWhatsapp size={32} />
                </a>

            </div>
            <div className="fixed bottom-3 left-2 flex space-x-2">
                <a
                    href="tel:+972523773738"
                    className="bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-white hover:text-blue-500 transition duration-300"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <IoCallOutline size={32} />
                </a>
            </div>
        </>
    );
};

export default Home;
