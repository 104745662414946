// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/en.json';
import he from './locales/he/he.json';
import ar from './locales/ar/ar.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    he: { translation: he },
    ar: { translation: ar },
  },
  lng: 'he', // default language
  fallbackLng: 'he',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
