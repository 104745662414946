/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTiktok, FaTwitter, FaWaze, FaWhatsapp, FaQrcode, FaTelegramPlane } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import 'swiper/swiper-bundle.css';
import Logo from './assets/BusinessCard2.png';
import Logo2 from './assets/MJGroup_blue.png';
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';
import { CgClose } from 'react-icons/cg';
import emailjs from '@emailjs/browser';
import QRCodeStyling from "qr-code-styling";
import AccessibilityButton from './components/AccessibilityButton';




const Preview = () => {
    const { t, i18n } = useTranslation();
    const [showAnswers, setShowAnswers] = useState([false, false, false, false]);
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const form = useRef({
        user_name: '',
        user_email: '',
        phone_number: '',
        message: '',
    });

    const isRtl = i18n.language === 'ar' || i18n.language === 'he';
    // Business hours data
    const time = [
        { id: 1, day: t('sunday'), time: null, bg: 'bg-[#9C020E]' }, // Closed on Sundays
        { id: 2, day: t('monday'), time: '09:00', bg: 'bg-[#029C4C]' },
        { id: 3, day: t('tuesday'), time: '09:00', bg: 'bg-[#029C4C]' },
        { id: 4, day: t('wednesday'), time: '09:00', bg: 'bg-[#029C4C]' },
        { id: 5, day: t('thursday'), time: '09:00', bg: 'bg-[#029C4C]' },
        { id: 6, day: t('friday'), time: '09:00', bg: 'bg-[#029C4C]' },
        { id: 7, day: t('saturday'), time: '09:00', bg: 'bg-[#029C4C]' },
    ];

    const slideImages = [
        {
            id: 1,
            url: `${Logo}`,
        },
        {
            id: 2,
            url: `${Logo2}`,
        },
        {
            id: 3,
            url: `${Logo}`,
        },
        {
            id: 4,
            url: `${Logo2}`,
        },
        {
            id: 5,
            url: `${Logo}`,
        },
        {
            id: 6,
            url: `${Logo2}`,
        },
    ];

    const linkShare = [
        {
            id: 1,
            name: 'WhatsApp',
            icon: <FaWhatsapp className='text-[24px] md:text-[28px] lg:text-[32px]' />,
            url: 'https://api.whatsapp.com/send?text=https://mycardly.me',
            bg: 'bg-[#008625]',

        },
        {
            id: 2,
            name: 'Facebook',
            icon: <FaFacebookF className='text-[24px] md:text-[28px] lg:text-[32px]' />,
            url: 'https://www.facebook.com/sharer/sharer.php?u=https://mycardly.me',
            bg: 'bg-[#063DAE]',

        },
        {
            id: 3,
            name: 'Twitter',
            icon: <FaTwitter className='text-[24px] md:text-[28px] lg:text-[32px]' />,
            url: 'https://twitter.com/intent/tweet?url=https://mycardly.me',
            bg: 'bg-[#19A1F4]',

        },
        {
            id: 4,
            name: 'LinkedIn',
            icon: <FaLinkedinIn className='text-[24px] md:text-[28px] lg:text-[32px]' />,
            url: 'https://www.linkedin.com/shareArticle?url=https://mycardly.me',
            bg: 'bg-[#181DF1]',

        },
        {
            id: 5,
            name: 'Instagram',
            icon: <FaInstagram className='text-[24px] md:text-[28px] lg:text-[32px]' />,
            url: 'https://www.instagram.com/?url=https://mycardly.me',
            bg: 'bg-[#E1308E]',

        },
        {
            id: 6,
            name: 'Telegram',
            icon: <FaTelegramPlane className='text-[24px] md:text-[28px] lg:text-[32px]' />,
            url: 'https://telegram.me/share/url?url=https://mycardly.me',
            bg: 'bg-[#4E70EE]',

        },
    ];

    const today = new Date().getDay(); // 0 is Sunday, 6 is Saturday
    const todayHours = time.find(day => day.id === today + 1); // Adjust for Sunday start

    // References for each section
    const logoRef = useRef(null);
    const aboutRef = useRef(null);
    const galleryRef = useRef(null);
    const faqRef = useRef(null);
    const contactRef = useRef(null);
    const qrCodeRef = useRef(null);
    const qrCodeInstance = useRef(null);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const sectionMap = {
            '#logo': logoRef,
            '#about': aboutRef,
            '#gallery': galleryRef,
            '#faq': faqRef,
            '#contact': contactRef,
        };
        if (sectionMap[location.hash]) {
            sectionMap[location.hash].current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    useEffect(() => {
        if (!qrCodeInstance.current) { // Ensure we create only one instance
            qrCodeInstance.current = new QRCodeStyling({
                data: "https://mycardly.me/preview",
                image: `${Logo}`,
                width: 200,
                height: 200,
                margin: 4,
                dotsOptions: {
                    color: "#4267b2",  // Dark blue color for dots
                    type: "dots" // Circular dots for a unique style
                },                
                imageOptions: {
                    crossOrigin: "anonymous",
                    margin: 4, // Slight margin to separate logo from the QR dots
                    imageSize: 0.25 // Making logo smaller for more QR visibility
                },
                cornersSquareOptions: {
                    color: "#FFEC00", // Same dark blue color for the corners
                    type: "extra-rounded" // Rounded corners for a soft look
                },
                cornersDotOptions: {
                    color: "#FFEC00",
                    type: "dot" // Dot style inside the corners for a cohesive look
                },
            });
        }

        if (qrCodeRef.current) {
            qrCodeInstance.current.append(qrCodeRef.current);
        }
    }, []);

    const toggleAnswer = (index) => {
        const updatedAnswers = [...showAnswers];
        updatedAnswers[index] = !updatedAnswers[index];
        setShowAnswers(updatedAnswers);
    };

    // EmailJS form handling 
    const handleSendEmail = (e) => {
        e.preventDefault();

        // Send the email using emailjs
        emailjs.sendForm(
            'service_879osg4', // Replace with your EmailJS service ID
            'template_vwflw9h', // Replace with your EmailJS template ID
            form.current,
            'Tr-QMjqqy2vDLU0Ou' // Replace with your EmailJS user ID (or public key)
        )
            .then((result) => {
                console.log('Email sent successfully:', result.text);
                // Optionally clear the form fields after sending
                form.current.reset();
            })
            .catch((error) => {
                console.error('Email sending failed:', error);
            });
    };

    const day = new Date().getDay();


    return (
        <>
        <AccessibilityButton />
        <div className="min-h-screen overflow-y-auto" dir={isRtl ? 'rtl' : 'ltr'}>
            {/* V-shaped Header with Background */}
            <div className="relative w-full h-64 md:h-[40vh] clip-shape" style={{ backgroundImage: `url(https://mycardly.me/${Logo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-blue-900/50 to-transparent"></div>
            </div>

            {/* Details Section */}
            <div ref={logoRef} className="bg-transparent flex flex-col items-center justify-center text-center max-w-3xl mx-auto p-8 mt-[-100px] rounded-md" style={{
                boxShadow: '0 8px 55px rgb(6 33 63 / 100%)',
            }}>
                <img src={`${Logo2}`} alt={t('businessName')} className="relative object-cover text-center  -mt-[20px] sm:-mt-[70px] md:-mt-[100px] lg:-mt-[70px]" />
                <h1 className="text-3xl font-bold text-[#1d4ed8] mt-2 mb-2">{t('business_name')}</h1>
                <h3 className="text-xl font-semibold text-[#1d4ed8] mb-4">{t('slogan')}</h3>
                <p className={`text-lg font-semibold text-white mb-4 ${todayHours.bg} px-4 py-2 rounded`}>
                    {day === todayHours?.id ? t('closed_today') : ` ${t('time_open',)} ${todayHours.time} `}
                </p>
                <a href="https://mycardly.me/mousajacoub.vcf" className="text-lg font-semibold text-[#1d4ed8] mb-20 cursor-pointer bg-yellow-300 hover:bg-yellow-400 px-4 py-2 rounded" download>{t('save_contact')}</a>

                <section ref={aboutRef} className="text-center mb-12 w-full px-4">
                    <h2 className="text-2xl font-semibold mb-4 text-[#1d4ed8]">{t('about_business')}</h2>
                    {Array.from({ length: 7 }, (_, i) => (
                        <p key={i} className="text-base md:text-lg mb-2 text-[#1d4ed8]">
                            {t(`about_description.${i + 1}`)}
                        </p>
                    ))}
                </section>

                {/* section social icons */}
                <section className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-4 justify-center">
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center w-12 h-12 text-[#1d4ed8]  rounded-full border-2 border-[#1d4ed8] bg-transparent transition">
                        <FaFacebookF size={20} />
                    </a>
                    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center w-12 h-12 text-[#1d4ed8]  rounded-full border-2 border-[#1d4ed8] bg-transparent transition">
                        <FaInstagram size={20} />
                    </a>
                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center w-12 h-12 text-[#1d4ed8]  rounded-full border-2 border-[#1d4ed8] bg-transparent transition">
                        <FaLinkedinIn size={20} />
                    </a>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center w-12 h-12 text-[#1d4ed8]  rounded-full border-2 border-[#1d4ed8] bg-transparent transition">
                        <FaTwitter size={20} />
                    </a>
                    <a href="https://waze.com" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center w-12 h-12 text-[#1d4ed8]  rounded-full border-2 border-[#1d4ed8] bg-transparent transition">
                        <FaWaze size={20} />
                    </a>
                    <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center w-12 h-12 text-[#1d4ed8]  rounded-full border-2 border-[#1d4ed8] bg-transparent transition">
                        <FaTiktok size={20} />
                    </a>
                </section>

                {/* Gallery Section */}
                <section ref={galleryRef} className="mb-12 max-w-full mx-auto px-2 md:px-4">
                    <h2 className="text-2xl font-semibold mb-4 text-[#1d4ed8]">{t('gallery_title')}</h2>
                    <div>
                        <Slide
                            className="w-full"
                            duration={4000}
                            swipe={true}
                            autoplay={true}
                            infinite={true}
                            indicators={true}
                            arrows={true}
                        >
                            {slideImages.map((image, index) => (
                                <div key={index} className="px-2">
                                    <img
                                        src={image.url}
                                        alt={`Image ${index}`}
                                        className="w-full h-auto object-cover rounded-lg"
                                    />
                                </div>
                            ))}
                        </Slide>
                    </div>
                </section>


                {/* FAQ Section */}
                <section ref={faqRef} className="max-w-2xl  mx-auto mb-12 w-full px-4 md:px-16">
                    <h2 className="text-2xl w-full font-semibold mb-4 text-[#1d4ed8] text-center">{t('faq_title')}</h2>
                    <div className="space-y-2">
                        {t('questions', { returnObjects: true }).map((question, index) => (
                            <div key={index} className="border border-gray-200 rounded-lg p-4 shadow-sm ">
                                <button onClick={() => toggleAnswer(index)} className="w-full  text-lg font-semibold text-[#1d4ed8]" >
                                    {question}
                                </button>
                                {showAnswers[index] && (
                                    <p className="mt-2 text-[#1d4ed8]">{t('answers', { returnObjects: true })[index]}</p>
                                )}
                            </div>
                        ))}
                    </div>
                </section>

                {/* Contact Section */}
                <section ref={contactRef} className="max-w-lg mx-auto mb-12 px-4">
                    <h2 className="text-2xl font-semibold mb-4 text-[#1d4ed8] text-center">{t('contact_title')}</h2>
                    <form ref={form} onSubmit={handleSendEmail} className="space-y-4">
                        <input type="text" name='to_email' value='into@mycardly.me' hidden />
                        <input type="text" name="user_name" placeholder={t('user_name')} className={`w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ${isRtl ? 'text-right' : ''}`} required />
                        <input type="email" name="user_email" placeholder={t('email_address')} className={`w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ${isRtl ? 'text-right' : ''}`} required />
                        <input type="tel" name="phone_number" placeholder={t('phone_number')} className={`w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ${isRtl ? 'text-right' : ''}`} required />
                        <textarea name="message" placeholder={t('message')} className={`w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ${isRtl ? 'text-right' : ''}`} required />
                        <button type="submit" className="w-full py-3 bg-[#1d4ed8] text-white font-semibold rounded hover:bg-blue-700 transition duration-300">{t('send')}</button>
                    </form>
                </section>

                {/* QR Code Button */}
                <div
                    onClick={openModal}
                    className="fixed z-50 bottom-4 left-4 bg-white  p-3 rounded-full shadow-lg hover:bg-gray-100 transition duration-300"
                    style={{ boxShadow: '0 0 20px rgba(16, 16, 182, 0.80)' }}
                >
                    <FaQrcode className="text-blue-500" size={28} />
                </div>

                {/* WhatsApp Button */}
                <a
                    href="https://wa.me/972523773738"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fixed z-50 bottom-4 right-4 bg-green-500 p-4 rounded-full shadow-lg hover:bg-green-600 transition duration-300"
                    style={{ boxShadow: '0 0 20px rgba(9, 169, 22, 0.80)' }}
                >
                    <FaWhatsapp className="text-white" size={28} />
                </a>

                {/* bottom popup */}
                <div className={`fixed bottom-0 w-[85%]  md:w-[600px] lg:w-[800px] bg-white p-2 rounded-t-3xl flex flex-col items-center justify-center shadow-2xl shadow-[#1d4ed8]  z-50`}
                    style={{
                        transform: isModalOpen ? 'translateY(0)' : 'translateY(100%)',
                        transition: 'transform 0.7s ease-in-out',
                    }}>
                    <div className="w-full h-[370px] md:h-[460px] p-4">
                        <div className="absolute top-6 right-6">
                            <button className="text-gray-500 hover:text-white hover:bg-red-500 rounded-full p-1 focus:outline-none" onClick={closeModal}>
                                <CgClose size={24} />
                            </button>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <h3 className="text-xl text-[#1d4ed8] font-semibold ">{t('qr_code')}</h3>
                            <div ref={qrCodeRef}
                                className="w-52 h-52 md:w-72 md:h-72 lg:w-80 lg:h-80 flex items-center justify-center mt-4"
                            />


                            {/* share with social icons row from linkShare */}
                            <div className="w-content flex justify-center items-center">
                                {linkShare.map((link, index) => (
                                    <a
                                        key={index}
                                        href={link.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={`${link.bg} text-white hover:text-white rounded-full p-2 mr-2 flex items-center justify-center`}
                                    >
                                        {link.icon}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="text-center mt-20 py-10 text-gray-600" style={{
                boxShadow: '0 0 15px rgb(6 33 63 / 100%)',
            }}>
                <p>{`${t('footer')} ${new Date().getFullYear()}`}</p>
                <div className='text-white p-4'>
                    <a href="https://mousajacoub.com" target="_blank" rel="noopener noreferrer" className="ml-4 p-3 hover:text-white rounded-full bg-[#1d4ed8]">
                        {t('powered_by')} <span className="text-yellow-300">{t('business_name')}</span>
                    </a>
                </div>
            </footer>
        </div>
        </>
    );
};

export default Preview;
