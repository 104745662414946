import { useEffect } from 'react';

const AccessibilityButton = () => {
  useEffect(() => {
    // Define the window interdeal object as required by the EqualWeb script
    window.interdeal = {
      sitekey: "85c90b242779744f98d8e33f607b5369",
      Position: "left",
      domains: {
        js: "https://cdn.equalweb.com/",
        acc: "https://access.equalweb.com/"
      },
      btnStyle: {
        vPosition: ["20%", "20%"],
        scale: ["0.5", "0.5"],
        color: {
          main: "#0a51f2",
          second: "#ffffff"
        },
        icon: {
          outline: true,
          type: 11,
          shape: "semicircle"
        }
      }
    };

    // Load the EqualWeb accessibility script dynamically
    const script = document.createElement('script');
    script.src = `${window.interdeal.domains.js}core/5.0.9/accessibility.js`;
    script.defer = true;
    script.integrity = 'sha512-dxjHZQgpVIG70EQus4+1KR3oj2KGyrtwR/nr4lY+tcMjrQ1Yb9V1SCKNVLGhD3CcPMgANKAqOk4ldI8WWNnQTw==';
    script.crossOrigin = 'anonymous';
    script.setAttribute('data-cfasync', 'true');

    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // This component doesn't render anything itself
};

export default AccessibilityButton;
